<template>
    <div>
        <PasswordResetForm v-if="token" :token="token"></PasswordResetForm>
        <PasswordResetAsk v-else></PasswordResetAsk>
    </div>
</template>


<script>
import PasswordResetAsk from "./PasswordResetAsk.vue";
import PasswordResetForm from "./PasswordResetForm.vue";

export default {
  components: {
    PasswordResetAsk,
    PasswordResetForm,
  },
  data() {
    return {
      token: null
    };
  },
  created() {
    this.token = this.$route.query.token;
    console.log(this.$route.query);
    console.log(this.token);
  },
};
</script>