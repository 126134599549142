
  import ApiService from "@/core/services/ApiService";
  import { defineComponent, ref } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import { useStore } from "vuex";
  import * as Yup from "yup";
  import { Actions } from "@/store/enums/StoreEnums";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  
  export default defineComponent({
    name: "password-reset",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    props: {
      token:  {
        type: String,
        required: true
      },
    },
    setup(props) {
      const store = useStore();
  
      const submitButton = ref<HTMLButtonElement | null>(null);
  
      //Create form validation object
      const forgotPassword = Yup.object().shape({
        password: Yup
            .string()
            .required()
            .min(8, 'Password must contain at least 8 characters')
            .matches(/^[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))$/, 'Password must contain at least one letter and one digit')
            .label("Password"),
      });
  
      //Form submit function
      const onSubmitForgotPassword = (values) => {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate loading indicator
        submitButton.value?.setAttribute("data-kt-indicator", "on");
  
        // Send login request
        ApiService.post(
          '/auth/reset-password',
          { password: values.password },
          null,
          { token: props.token }
        )
          .then(() => {
            Swal.fire({
              text: "Your password has been reset! You can now log in the dashboard.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(() => {
              window.location.href = "/sign-in";
            });
            
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          })
          .catch(() => {
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          });
      };
  
      return {
        onSubmitForgotPassword,
        forgotPassword,
        submitButton,
      };
    },
  });
  